'use client';

import { useState, useEffect, PropsWithChildren } from 'react';
import currency from 'currency.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShieldIcon from '@mui/icons-material/Shield';
import { Stack, Typography, Box, CircularProgress, SvgIcon, Chip } from '@mui/material';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { ReactComponent as IconParcel } from '@qb/frontend/icons/icon-parcel.svg';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { Widget } from '@/shared/Constants';
import { PartDisplayDataResponse, PartPublicQtyAndPriceResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { CallToActionAddToCart } from './CallToActionAddToCart';
import { CallToActionRequestQuote } from './CallToActionRequestQuote';
export const TrustBoxPriceDetails = ({
  part,
  publicQtyAndPrice
}: Pick<PartDisplayDataResponse, 'part' | 'publicQtyAndPrice'>) => {
  const {
    onHandQty,
    price,
    originalPrice,
    shipProcessingDays
  } = publicQtyAndPrice;
  const isMarketablePart = onHandQty > 0 && !!price;
  const isNoPriceNoAvailability = onHandQty === 0 && !price;
  const isMobile = useIsMobile();
  const [qty, setQty] = useState(1);
  const numberInput = <Stack direction={isNoPriceNoAvailability ? 'row' : 'column'} gap={1} justifyContent="center" alignItems="center">
      {!isMobile && <Typography variant="bodyMediumPrimary" color="text.primary">
          Select Qty:
        </Typography>}
      <NumberInput onValueChange={setQty} initialValue={qty} />
    </Stack>;
  if (isMarketablePart) {
    const ctaButtons = !!price && <CallToActionAddToCart part={part} price={price} qty={qty} onHandQty={onHandQty} />;
    if (isMobile) {
      return <>
          <ShipWithinLabel shipProcessingDays={shipProcessingDays} />
          <PriceFields price={price} originalPrice={originalPrice} />
          <MobileCallToActionBar>
            {numberInput}
            {ctaButtons}
          </MobileCallToActionBar>
        </>;
    }
    return <>
        <ShipWithinLabel shipProcessingDays={shipProcessingDays} />
        <WarrantyLabel />
        <Box sx={{
        px: 2,
        pb: 2
      }}>
          <PriceFields price={price} originalPrice={originalPrice} />
          <Stack direction="row" justifyContent="space-between" alignItems="end" mb={3}>
            <StockLabel onHandQty={onHandQty} />
            {numberInput}
            <SubTotal qty={qty} price={price} />
          </Stack>
          {ctaButtons}
        </Box>
      </>;
  }

  // No stock info:
  if (onHandQty === 0 && !!price) {
    // console.log('No stock info:');

    const header = <>
        <Typography variant="bodyMedium" mb={2}>
          Request a quote, and we will send availability within minutes.
        </Typography>
      </>;
    if (isMobile) {
      return <>
          <PriceFields price={price} originalPrice={originalPrice} />
          {header}
          <MobileCallToActionBar>
            {numberInput}
            <CallToActionRequestQuote part={part} qty={qty} />
          </MobileCallToActionBar>
        </>;
    }
    return <>
        <WarrantyLabel />
        <Box sx={{
        pl: 2,
        pr: 2,
        pb: 2
      }}>
          <PriceFields price={price} originalPrice={originalPrice} />
          {header}
          <Stack direction="row" justifyContent="space-between" mb={3}>
            {numberInput}
            <SubTotal qty={qty} price={price} />
          </Stack>
          <CallToActionRequestQuote part={part} qty={qty} />
        </Box>
      </>;
  }

  // No price:
  if (onHandQty > 0) {
    // console.log('No price:');

    const header = <>
        <Typography variant="h4" component="p" color="secondary" mb={1} mt={1}>
          Get a quick Quote!
        </Typography>
        <Typography variant="bodyMedium" mb={2}>
          Request a quote, and we will send you price within minutes.
        </Typography>
      </>;
    if (isMobile) {
      return <>
          {header}
          <MobileCallToActionBar>
            {numberInput}
            <CallToActionRequestQuote part={part} qty={qty} />
          </MobileCallToActionBar>
        </>;
    }
    return <>
        <WarrantyLabel />
        <Box sx={{
        pl: 2,
        pr: 2,
        pb: 2
      }}>
          {header}
          <Stack direction="row" justifyContent="space-between" mb={3}>
            <StockLabel onHandQty={onHandQty} />
            {numberInput}
          </Stack>
          <CallToActionRequestQuote part={part} qty={qty} />
        </Box>
      </>;
  }

  // No price - no availability:
  // console.log('No price - no availability:');

  const header = <>
      <Typography variant="h4" component="p" color="secondary" mb={1} mt={1}>
        Get a quick Quote!
      </Typography>
      <Typography variant="bodyMedium" mb={2}>
        Request a quote, and we will send you price and availability within
        minutes.
      </Typography>
    </>;
  if (isMobile) {
    return <>
        {header}
        <MobileCallToActionBar>
          {numberInput}
          <CallToActionRequestQuote part={part} qty={qty} />
        </MobileCallToActionBar>
      </>;
  }
  return <>
      <WarrantyLabel data-sentry-element="WarrantyLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      <Box sx={{
      pl: 2,
      pr: 2,
      pb: 2
    }} data-sentry-element="Box" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {header}
        <Stack direction="row" justifyContent="space-between" mb={3} data-sentry-element="Stack" data-sentry-source-file="TrustBoxPriceDetails.tsx">
          {numberInput}
        </Stack>
        <CallToActionRequestQuote part={part} qty={qty} data-sentry-element="CallToActionRequestQuote" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      </Box>
    </>;
};
const MobileCallToActionBar = ({
  children
}: PropsWithChildren) => <Stack display="grid" columnGap={5} gridTemplateColumns="auto 1fr" gridTemplateRows="auto auto" sx={{
  bgcolor: 'common.backgroundColor',
  position: 'fixed',
  bottom: Widget.VERTICAL_STYLE_NAVBAR_HEIGHT,
  left: 0,
  right: 0,
  zIndex: 'appBar',
  py: 1,
  px: 3,
  boxShadow: 2
}} data-sentry-element="Stack" data-sentry-component="MobileCallToActionBar" data-sentry-source-file="TrustBoxPriceDetails.tsx">
    {children}
  </Stack>;
const ShipWithinLabel = ({
  shipProcessingDays
}: Pick<PartPublicQtyAndPriceResponse, 'shipProcessingDays'>) => {
  return <Stack direction={['row', 'row', 'row-reverse']} alignItems="center" sx={{
    bgcolor: 'success.12p',
    borderRightStyle: 'solid',
    borderRightWidth: [0, 0, 8],
    borderRightColor: 'success.main',
    py: [1, 1, 1.5],
    pr: 1.5,
    pl: [0.5, 0.5, 1.5],
    mb: [1, 1, 0],
    width: ['fit-content', 'fit-content', '100%']
  }} data-sentry-element="Stack" data-sentry-component="ShipWithinLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx">
      <SvgIcon component={IconParcel} inheritViewBox sx={{
      color: 'success.main',
      fontSize: 32
    }} data-sentry-element="SvgIcon" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      <Typography variant="h6" component="p" mr={[0, 0, 1.5]} ml={[1, 1, 0]} data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {shipProcessingDays === 0 ? 'This item can ship within 24h!' : `This item can ship within ${shipProcessingDays} days!`}
      </Typography>
    </Stack>;
};
const WarrantyLabel = () => <Stack alignItems="end" data-sentry-element="Stack" data-sentry-component="WarrantyLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx">
    <Stack direction="row" alignItems="center" display="inline-flex" sx={{
    bgcolor: 'success.12p',
    p: 0.5,
    mt: 1
  }} data-sentry-element="Stack" data-sentry-source-file="TrustBoxPriceDetails.tsx">
      <ShieldIcon sx={{
      color: 'success.main'
    }} data-sentry-element="ShieldIcon" data-sentry-source-file="TrustBoxPriceDetails.tsx" />
      <Typography variant="bodySmall" color="text.primary" ml={0.5} data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        Manufacturer warranty
      </Typography>
    </Stack>
  </Stack>;
const StockLabel = ({
  onHandQty
}: Pick<PartPublicQtyAndPriceResponse, 'onHandQty'>) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
  }, []);
  const verifyingLabel = isLoading ? 'Stock: Verifying...' : 'Stock:';
  return <Stack data-sentry-element="Stack" data-sentry-component="StockLabel" data-sentry-source-file="TrustBoxPriceDetails.tsx">
      <Typography variant="bodyMediumPrimary" color="text.primary" data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {verifyingLabel}
      </Typography>
      <Stack direction="row" alignItems="center" display="inline-flex" sx={{
      transition: 'all 0.5s ease',
      bgcolor: isLoading ? 'common.white' : 'success.12p',
      py: 0.5,
      px: 1,
      pr: 2,
      mt: 1
    }} data-sentry-element="Stack" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {isLoading ? <CircularProgress size={24} sx={{
        color: 'text.primary'
      }} /> : <>
            <CheckCircleIcon sx={{
          color: 'success.main'
        }} />
            <Typography variant="bodyLargePrimary" color="text.primary" ml={0.75}>
              {onHandQty}
            </Typography>
          </>}
      </Stack>
    </Stack>;
};
const SubTotal = ({
  price,
  qty
}: {
  price: number;
  qty: number;
}) => <Stack sx={{
  minWidth: 100
}} alignItems="end" data-sentry-element="Stack" data-sentry-component="SubTotal" data-sentry-source-file="TrustBoxPriceDetails.tsx">
    <div>
      <Typography variant="h4" component="p" align="center" mb={1} data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {currency(price, {
        fromCents: true
      }).multiply(qty).format()}
      </Typography>
      <Typography variant="bodySmall" color="grey.500" fontSize={11} align="center" data-sentry-element="Typography" data-sentry-source-file="TrustBoxPriceDetails.tsx">
        {formatCents(price)} each
      </Typography>
    </div>
  </Stack>;
const PriceFields = ({
  originalPrice,
  price
}: Pick<PartPublicQtyAndPriceResponse, 'originalPrice' | 'price'>) => {
  const discountPercentage = getDiscountPercentage(originalPrice, price);
  const isDiscounted = !!discountPercentage;
  return <>
      {isDiscounted && <div>
          <Chip size="large" color="accent" label={`${discountPercentage}% off`} sx={{
        ml: 'auto'
      }} />
        </div>}
      {!!price && <Typography variant="h2" mb={[0, 0, 0.5]}>
          {formatCents(price)}
        </Typography>}
      {isDiscounted && !!originalPrice && <Typography variant="h4" mb={0.5} sx={{
      color: 'text.secondary',
      textDecoration: 'line-through'
    }}>
          {formatCents(originalPrice)}
        </Typography>}
    </>;
};