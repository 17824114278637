'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import { Close } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, IconButton, Dialog, Stack } from '@mui/material';
import noImageImg from '@qb/frontend/icons/noImage.svg';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { PartDisplayDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
export const PartPhotoDialog = ({
  part: {
    images,
    partNumber,
    manufacturer,
    description
  }
}: Pick<PartDisplayDataResponse, 'part'>) => {
  const constructedImageUrl = getFirstImage(images);
  const [open, setOpen] = useState(false);
  const alt = `${manufacturer.name} ${partNumber} ${description}`;
  const logoHeight = !!manufacturer.logoURL ? 30 : 0;
  return <>
      <Stack direction="column" gap={1} sx={{
      position: 'relative',
      border: '2px solid',
      borderColor: 'common.backgroundColor',
      p: {
        xs: 1,
        lg: 2
      },
      height: [260, 300 + logoHeight]
    }} data-sentry-element="Stack" data-sentry-source-file="PartPhotoDialog.tsx">
        <IconButton onClick={() => setOpen(true)} aria-label="Zoom In" sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2
      }} data-sentry-element="IconButton" data-sentry-source-file="PartPhotoDialog.tsx">
          <ZoomInIcon data-sentry-element="ZoomInIcon" data-sentry-source-file="PartPhotoDialog.tsx" />
        </IconButton>
        <Box sx={{
        position: 'relative',
        width: '100%',
        height: ['100%']
      }} data-sentry-element="Box" data-sentry-source-file="PartPhotoDialog.tsx">
          <Image src={constructedImageUrl || noImageImg} alt={alt} title={alt} priority fill style={{
          objectFit: 'contain'
        }} data-sentry-element="Image" data-sentry-source-file="PartPhotoDialog.tsx" />
        </Box>
        {!!manufacturer.logoURL && <Box sx={{
        position: 'relative',
        width: '100%',
        height: 30,
        display: ['none', 'block']
      }}>
            <Image src={manufacturer.logoURL} alt={manufacturer.name} title={manufacturer.name} priority fill style={{
          objectFit: 'contain'
        }} />
          </Box>}
      </Stack>
      <Dialog sx={{
      '& .MuiDialog-paper': {
        display: 'contents'
      },
      '& .MuiDialog-container': {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    }} open={open} onClose={() => setOpen(false)} data-sentry-element="Dialog" data-sentry-source-file="PartPhotoDialog.tsx">
        <IconButton aria-label="close" onClick={() => setOpen(false)} color="error" sx={{
        position: 'absolute',
        left: 12,
        top: 12,
        cursor: 'zoom-out'
      }} data-sentry-element="IconButton" data-sentry-source-file="PartPhotoDialog.tsx">
          <Close data-sentry-element="Close" data-sentry-source-file="PartPhotoDialog.tsx" />
        </IconButton>
        <Image onClick={() => setOpen(false)} src={constructedImageUrl || noImageImg} alt={alt} fill style={{
        objectFit: 'contain',
        cursor: 'zoom-out'
      }} data-sentry-element="Image" data-sentry-source-file="PartPhotoDialog.tsx" />
      </Dialog>
    </>;
};