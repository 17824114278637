'use client';

import { Typography, Box, Container } from '@mui/material';
import { PartCardHorizontal } from '@qb/frontend/components/PartCards/PartCardHorizontal';
import { PartCardLarge } from '@qb/frontend/components/PartCards/PartCardLarge';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { BaseCarousel } from '@/components/Common/BaseCarousel/BaseCarousel';
import { PartRelevantPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
const GA4_ITEM_LIST_NAME = 'relevant_products';
type Props = {
  parts: PartRelevantPartsDataResponse['parts'];
};
const PartRelevantPartsDesktop = ({
  parts
}: Props) => <BaseCarousel slidesCount={parts.length} slidesToShow={{
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5
}} data-sentry-element="BaseCarousel" data-sentry-component="PartRelevantPartsDesktop" data-sentry-source-file="PartRelevantPartsCarousel.tsx">
    {parts.map(part => <Box key={part.id} sx={{
    px: 1
  }}>
        <PartCardLarge part={part} key={part.id} />
      </Box>)}
  </BaseCarousel>;
const PartRelevantPartsMobile = ({
  parts
}: Props) => <Box display="grid" data-sentry-element="Box" data-sentry-component="PartRelevantPartsMobile" data-sentry-source-file="PartRelevantPartsCarousel.tsx">
    {parts.map((part, index) => <PartCardHorizontal key={part.id} part={part} ga4data={{
    itemListName: GA4_ITEM_LIST_NAME,
    itemIndex: index
  }} />)}
  </Box>;
export const PartRelevantPartsCarousel = ({
  parts
}: Props & {
  partAbsoluteUrl?: string;
}) => {
  const isMobile = useIsUnderBreakpoint('sm');
  return <Box sx={{
    bgcolor: [null, 'common.backgroundColor'],
    pt: [0, 3, 4],
    pb: [0, 3, 5],
    mt: [3, 3, 5]
  }} data-sentry-element="Box" data-sentry-component="PartRelevantPartsCarousel" data-sentry-source-file="PartRelevantPartsCarousel.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="PartRelevantPartsCarousel.tsx">
        <Typography variant="h4" component="h2" sx={{
        typography: {
          xs: 'h5',
          sm: 'h4'
        },
        mb: [1.5, 2, 3]
      }} data-sentry-element="Typography" data-sentry-source-file="PartRelevantPartsCarousel.tsx">
          Relevant products
        </Typography>
        {isMobile ? <PartRelevantPartsMobile parts={parts} /> : <PartRelevantPartsDesktop parts={parts} />}
      </Container>
    </Box>;
};