'use client';

import { createContext, PropsWithChildren } from 'react';
import { tenantAliasObs } from '@/global/tenantStore/tenantStore';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { AppDisplayDataResponse } from '@/shared/types/controllers/AppControllerTypes';
export const TenantStoreContext = createContext<AppDisplayDataResponse['app'] | null>(null);
export const TenantStoreContextProvider = ({
  children,
  appInfo,
  tenantAlias
}: PropsWithChildren<{
  appInfo: AppDisplayDataResponse['app'];
  tenantAlias: TenantAlias;
}>) => {
  // Makes the tenantAlias available to all client and server side _components.
  tenantAliasObs.set(tenantAlias);
  return <TenantStoreContext.Provider value={appInfo} data-sentry-element="unknown" data-sentry-component="TenantStoreContextProvider" data-sentry-source-file="TenantStoreContext.tsx">
      {children}
    </TenantStoreContext.Provider>;
};